  /* Cambia el color de la barra de desplazamiento */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  /* Cambia el color de la barra de desplazamiento al hacer clic */
  ::-webkit-scrollbar-thumb {
    background-color: #0c4a6edc;
    opacity: 100;
    border-radius: 10px;
  }

  input[type='number']::-webkit-outer-spin-button,
        input[type='number']::-webkit-inner-spin-button,
        input[type='number'] {
          -webkit-appearance: none;
          margin: 0;
          -moz-appearance: textfield !important;
        }